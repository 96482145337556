import React, {useState} from "react";
import { Menu } from 'antd';
import { notification } from 'antd';

export default function ProfileSettings(props) {
    const [hasConnectedAccount, setHasConnectedAccount] = React.useState(null);
    const [isRestricted, setIsRestricted] = React.useState(false);
    // Can be removed? Not actaully used
    const [accountLink, setAccountLink] = React.useState("");



    React.useEffect(() => {
      const checkConnectedAccount = async () => {
        if (!props.userProfile.email) {
          console.log("User ID is not set, skipping check.");
          return;
        }
  
        if (!props.userProfile) {
          console.log("userProfile is not set, skipping check.");
          return;
        }
  
  
        console.log("props.userProfile.email: ", props.userProfile.email);
        console.log("props userProfile: ", JSON.stringify(props.userProfile));
        console.log("props userProfile stripe_account_id: ", JSON.stringify(props.userProfile.stripe_account_id));
  
  
        const stripe_acct_id = props.userProfile.stripe_account_id;
    
        try {
          console.log("Checking connected account for user:", props.userProfile.email, stripe_acct_id);
          const response = await fetch('http://localhost:5000/check-connected-account', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId: stripe_acct_id }),
          });
    
          const data = await response.json();
  
          console.log("Request sent to /check-connected-account with creatorId: ", stripe_acct_id);
          console.log("Response from /check-connected-account: ", data);
        
  
          console.log("Data from check-connected-account: ", data.hasConnectedAccount);
          setHasConnectedAccount(data.hasConnectedAccount);
          setIsRestricted(data.isRestricted);
    
          if (!hasConnectedAccount || isRestricted) {
            console.log("No connected account found, generating account link...");
            // Generate account link if no connected account
            const response = await fetch('http://localhost:5000/create-account-link', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ accountId: stripe_acct_id }), // Dummy price to create account
            });
    
            const accountData = await response.json();
            setAccountLink(accountData.url);
          }
        } catch (error) {
          console.error("Error checking connected account: ", error);
        }
      };
    
      checkConnectedAccount();
    }, [props.userProfile.email]); // Dependency array includes props.id

    const showCreateAccountNotification = () => {
      notification.warning({
        message: 'Complete Account Setup',
        description: 'Complete account setup and upload a video to view this feature.',
        placement: 'topRight',
      });
    };
  


    const handleClick = (e) => {
        console.log("Clicked menu item:", e.key);
        if (e.key === "1") {
            props.removeUser();
          }
        if (e.key === "2") {
            createCustomerPortalSession(props.userProfile.stripe_account_id, props.userProfile.email);
          }
        if (e.key === "3") {
            createCustomerPortalSession(props.userProfile.stripe_account_id, props.userProfile.email);
        }
        if (e.key === "4") {
            if(hasConnectedAccount) {
              createStripeDashboardLink(props.userProfile.stripe_account_id);
            } else {
              showCreateAccountNotification();
            }
        }

         if (e.key === "5") {
          window.open("https://forms.gle/WvJtubwg1DHHhXh7A", "_blank"); 
        }
      };

      const createCustomerPortalSession = async (stripeAccountId, email) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/create-customer-portal-session`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ stripeAccountId, email, returnUrl: `${process.env.REACT_APP_BASE_URL}/profile` }),
          });
      
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error creating customer portal session: ${errorText}`);
          }
      
          const result = await response.json();
          window.location.href = result.url;
        } catch (error) {
          console.error('Error creating customer portal session:', error);
        }
      };


      const createStripeDashboardLink = async (stripeAccountId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/create-dashboard-link`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ stripeAccountId }),
          });
      
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error creating Stripe dashboard link: ${errorText}`);
          }
      
          const result = await response.json();
          window.location.href = result.url;
        } catch (error) {
          console.error('Error creating Stripe dashboard link:', error);
        }
      };    

      const items = [
        {
          type: 'group',
          label: <span className="menu-group-label">General Settings</span>,
          children: [
            { key: '1', label: 'Logout' },
            { key: '2', label: 'My Subscriptions' },
            { key: '5', label: 'Feedback/Contact' },
          ],
        },
        {
          type: 'group',
          label: <span className="menu-group-label creator">Creator Settings</span>,
          children: [
            { key: '4', label: 'View My Dashboard' },
            // { key: '3', label: 'View Subscribers' },
          ],
        },
      ];
        

    return (
        <div className="menu-container">
            <Menu onClick={handleClick} mode="vertical" selectable={false} items={items} />

            {/* <Menu onClick={handleClick} mode="vertical" selectable={false}>
            <Menu.Item key="1">Logout</Menu.Item>
            <Menu.Item key="2">My Subscriptions</Menu.Item>
            <Menu.Item key="3">View Subsribers</Menu.Item>
            <Menu.Item key="4">View My Dashboard</Menu.Item>
            <Menu.Item key="5">Feedback/Contact</Menu.Item>
            </Menu> */}
            {/* <div>{JSON.stringify(props.userProfile)}</div>
            <div>{props.userProfile}</div>
            <div>{props.email}</div> */}

<div>{hasConnectedAccount !== null ? hasConnectedAccount.toString() : 'Loading...'}</div>

        </div>
    );
    }   