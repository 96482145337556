// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5bULzlacYiQI73ITLZNihHJi_HN_bJEo",
  authDomain: "longershorts-3ed9a.firebaseapp.com",
  projectId: "longershorts-3ed9a",
  storageBucket: "longershorts-3ed9a.appspot.com",
  messagingSenderId: "885865939712",
  appId: "1:885865939712:web:acd3f0a610cb0dae2929e5",
  measurementId: "G-LEHW82S98E",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const shortsCollection = collection(app, "shorts");
// // const analytics = getAnalytics(app);

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const shortsCollection = collection(db, "shorts");
export const usersCollection = collection(db, "users");

