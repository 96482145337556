import React from "react";
import ShortThumbnail from "./ShortThumbnail";
import { useParams } from "react-router-dom";
import { onSnapshot, addDoc, doc, deleteDoc, setDoc, getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { db, shortsCollection } from "../utils/Firebase";
import { useNavigate } from "react-router-dom";



export default function ViewUser() {
    const { uid } = useParams();
    const [shorts, setShorts] = React.useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        // console.log("ViewUser.jsx: uid: ", uid);
        const fetchShorts = async () => {
            const db = getFirestore();
            const shortsCollection = collection(db, 'shorts');
            const q = query(shortsCollection, where('uid', '==', uid));
            const snapshot = await getDocs(q);

            const filteredShortsArray = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            }));

        setShorts(filteredShortsArray);
        };

        fetchShorts();
    }, []);
    
    // React.useEffect(() => {
    //   const unsubscribe = onSnapshot(shortsCollection, function (snapshot) {
    //     const shortsArray = snapshot.docs.map((doc) => ({
    //       ...doc.data(), //,
    //       // id: doc.id,
    //     }));
    //     setShorts(shortsArray);
    //   });
    //   return unsubscribe;
    // }, []);

    const mapped_shorts = shorts.map((i) => {
        return (
          <ShortThumbnail
            // onClick={() => handleClick()}
            image={i.image}
            title={i.title}
            video={i.video}
            id={i.id}
            key={i.id}
          />
        );
      });
  

    return (
        <div className="app--body">{mapped_shorts}</div>
    );
}