// src/utils/loginUtils.js
import { decryptValue } from './cryptoUtils';

export async function getUserEmailFromLocalStorage() {
  const localStorageKey = "userKey";
  const defaultReturnValue = "Please login";

  let localStorageValue = localStorage.getItem(localStorageKey);
  if (!localStorageValue || localStorageValue === defaultReturnValue) {
    return defaultReturnValue;
  }

  try {
    localStorageValue = await decryptValue(localStorageValue);
    const userJson = JSON.parse(localStorageValue);

    console.log('Decrypted response:', localStorageValue);
    console.log('Decrypted response object:', userJson);
    console.log('Decrypted response email:', userJson.email);

    return userJson.email;
  } catch (error) {
    console.error('Error decrypting or parsing localStorage value:', error);
    return defaultReturnValue;
  }
}



export async function getUserFromLocalStorage() {
    const localStorageKey = "userKey";
    const defaultReturnValue = "Please login";
  
    let localStorageValue = localStorage.getItem(localStorageKey);
    if (!localStorageValue || localStorageValue === defaultReturnValue) {
      return defaultReturnValue;
    }
  
    try {
      localStorageValue = await decryptValue(localStorageValue);
      const userJson = JSON.parse(localStorageValue);
  
      console.log('Decrypted response:', localStorageValue);
      console.log('Decrypted response object:', userJson);
      console.log('Decrypted response email:', userJson.email);
  
      return userJson;
    } catch (error) {
      console.error('Error decrypting or parsing localStorage value:', error);
      return defaultReturnValue;
    }
  }