import React from "react";

function handleSubscribe() {
  console.log("in handle Subscribe");
}

function backToStart() {
  console.log("in back to start");
}

export default function ShortPaywall(props) {
  return (
    <div className="short--paywall">
      <img src={props.image} className="paywall--image" />
      <div className="paywall--overlay">
        <div>
          <p className="paywall--subscribe-text">Subscribe to watch content</p>
          <button
            className="paywall--subscribe-button"
            onClick={props.onSubsribe}
          >
            Subscribe
          </button>
        </div>
        <div>
          <p className="paywall--back-text">Restart Video</p>
          <button
            className="paywall--back-button"
            onClick={props.onRestartClick}
          >
            Restart
          </button>
        </div>
      </div>
    </div>
  );
}
