import React from "react";
import { useNavigate } from "react-router-dom";

export default function ShortThumbnail(props) {
  const navigate = useNavigate();


  const handleClick = () => {
    // props.onClick(`/v/${props.id}`); // Use the passed onClick function
    navigate(`/v/${props.id}`);
  };

  return (
    <div className="short" onClick={handleClick}>
      <img src={props.image} className="short--video" />
      <h3 className="short--title">{props.title}</h3>
    </div>
  );
}
