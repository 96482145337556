import React, { useRef, useEffect } from "react";
import Player from "react-player";
import ShortPaywall from "./ShortPaywall";
import { json, useNavigate } from "react-router-dom";
import { Button, notification} from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import { getUserEmailFromLocalStorage, getUserFromLocalStorage } from '../utils/localStorageUtils';
import { loadStripe } from '@stripe/stripe-js';


export default function Short(props) {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = React.useState(0);
  const [paywall, setPaywall] = React.useState(false);
  const playerRef = useRef(null);
  const [isSubscribed, setIsSubscribed] = React.useState('');
  const [myVideo, setMyVideo] = React.useState(null);
  const [loggedInEmail, setLogedInEmail] = React.useState('');
  const [loggedInUser, setLogedInUser] = React.useState('');
  const [subscriptions, setSubscriptions] = React.useState('empty');
  

  useEffect(() => {
    if (props.uid === loggedInUser.uid) {
      setMyVideo(true);
    } else {
      setMyVideo(false);
    }


  }, [props.uid, loggedInUser, loggedInUser.uid]);


  useEffect(() => {
    async function fetchUserEmail() {
      const user = await getUserFromLocalStorage();
      const userEmail = user.email;
      console.log('User email in here 1234:', userEmail);
      setLogedInEmail(userEmail);
      setLogedInUser(user);
    }

    fetchUserEmail();
  }, []);


  useEffect(() => {
    async function checkSubscription() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/check-subscriptions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            stripeAccountId: props.stripe_account_id,
            viewerEmail: loggedInEmail,
          }),
        });

        const result = await response.json();
        setSubscriptions(JSON.stringify(result));
        console.log('Subscription result:', result);

        // Check if the user is subscribed to any product by the account
        if (result.subscribed) {
          const activeSubscription = result.subscriptions.find(sub => sub.status === 'active');
          if (activeSubscription) {
            setIsSubscribed(true);
          }
        }       

      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    }

    if (loggedInEmail && loggedInEmail !== '') {
      checkSubscription();
    }
  }, [loggedInEmail]);


  //For debugging only 
  // useEffect(() => {


  const handleNavigateUserProfile = () => {
    // props.onClick(`/v/${props.id}`); // Use the passed onClick function
    navigate(`/u/${props.uid}`);
  };


  function handleRestart() {
    setPaywall(false);
    setCurrentTime(0);
  }

  function handleProgress(progress) {
    setCurrentTime(progress.playedSeconds);
    console.log(progress.playedSeconds);

    if (isSubscribed == false && currentTime >= props.paywallStart && playerRef.current) {
      // Check if playerRef is available
      setPaywall(true);
      console.log("playerRef.current:", playerRef.current); // Log the value
      // playerRef.current.pause(); // Pause the video (only if playerRef is not null)
      const internalPlayer = playerRef.current.getInternalPlayer();
      if (internalPlayer && typeof internalPlayer.pause === "function") {
        internalPlayer.pause(); // Pause the video (only if internalPlayer is not null and has a pause method)
      } else {
        console.error("Pause method not available on the internal player");
      }
    } else {
      setPaywall(false);
    }
  }

  function handleShare() {
    const url = `${process.env.REACT_APP_BASE_URL}/v/${props.id}`;
    navigator.clipboard.writeText(url).then(() => {
      notification.success({
        message: 'Link Copied',
        description: 'Link has been copied to your clipboard.',
        placement: 'topRight',
      });
    }).catch((error) => {
      console.error('Error copying URL:', error);
    });
  }

  async function handleSubscribe() {
    if(isSubscribed) {
      notification.info({
        message: 'Subscription Status',
        description: 'You are already subscribed.',
        placement: 'topRight',
      });
      return;
    }

    if(myVideo) {
      notification.info({
        message: 'Subscription Status',
        description: 'You can\'t subscribe to yourself',
        placement: 'topRight',
      });
      return;
    }


    try {
      // Call your backend to create the checkout session
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stripeAccountId: props.stripe_account_id, // The Stripe Account ID of the content creator
          subscriberEmail: loggedInEmail, // The email of the subscriber
          sid: props.id,
        }),
      });

      const session = await response.json();

      // Redirect to Stripe Checkout
      window.location.href = session.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }

  }

  return (
    <div className="short">
      {!paywall ? (
        <Player
          url={props.video}
          className="short--video"
          onProgress={handleProgress}
          ref={playerRef}
          controls
        />
      ) : (
        <ShortPaywall image={props.image} onRestartClick={handleRestart} onSubsribe={handleSubscribe} />
      )}

      <div className="short--deatil-title">{props.title}</div>
      <div className="short--detail-container">
        {/* <div className="short--user" onClick={handleNavigateUserProfile} >View Profile</div> */}
        <Button size="small" className={myVideo == true ? "short--view-profile" : "short--view-my-profile"}  type="default" onClick={handleNavigateUserProfile}>{myVideo == true ? "My Profile" : "View Profile" }</Button>   
        <Button size="small" className={isSubscribed ? "short--subscribed" : "short--subscribe"}  type="default" onClick={handleSubscribe}>
          {isSubscribed ? "Subscribed" : "Subscribe"}
        </Button>
        <Button size="small" className="short--share" icon={<ShareAltOutlined />} type="default" onClick={handleShare} />     
      </div>
      <br />  
      <br />
      <div>Creator UID: {props.uid}:</div>
      <div>Viewer UID: {loggedInUser.uid}:</div>
      <div>My Video Flag: |{String(myVideo)}|</div>

      {/* <div>Current time: {currentTime}</div>
      <div>Paywall start: {props.paywallStart}</div>
      <div>Subscriptions: {subscriptions}</div>
      <div>{paywall.toString()}</div>
      <div>stripe account id: {props.stripe_account_id}:</div>
      <div>Logged In Email: {loggedInEmail}:</div>
      <div>Creator Email: {props.email}:</div>
      <div>Creator UID: {props.uid}:</div>
      <div>Viewer UID: {loggedInUser.uid}:</div>
      <div>All User dims: {JSON.stringify(loggedInUser)}</div>
      <br />
      <div>All props: {JSON.stringify(props)}</div> */}
    </div>
  );
}


