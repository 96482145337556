import React from "react";
import Upload from "./Upload";
import MyShorts from "./MyShorts";
import ProfileSettings from "./ProfileSettings";
import { Tabs } from 'antd';
import GetGoogleLogin from "./GetGoogleLogin";
import { decryptValue } from '../utils/cryptoUtils';


const { TabPane } = Tabs;

export default function Profile() {
  const [profileView, setProfileView] = React.useState("Settings");
  const options = ["My Shorts", "Upload", "Settings"];
  const [email, setEmail] = React.useState('Loading...');
  const [userProfile, setUserProfile] = React.useState(null);

  React.useEffect(() => {
    async function fetchEmail() {
      // const email = await getEmailFromLocalStorage();
      const userProfile = await getUserFromLocalStorage();
      // console.log("User profile: ", userProfile);
      // console.log("User profile email: ", userProfile.email);
      setEmail(userProfile.email); //test chanign to setEmail(userProfile.email); should work 
      setUserProfile(userProfile);
    }
    fetchEmail();
    console.log("Email: ", email);
  }, []);


  const items = [
    {
      key: 'Settings',
      label: 'Settings',
      children: (
        <div>
          <ProfileSettings userProfile={userProfile} removeUser={removeUserFromLocalStorage} />
        </div>
      ),
    },
    {
      key: 'My Shorts',
      label: 'My Shorts',
      children: (
        <div>
          <MyShorts userProfile={userProfile} />
        </div>
      ),
    },
    {
      key: 'Upload',
      label: 'Upload',
      children: (
        <Upload id={email} userProfile={userProfile} />
      ),
    },
  ];



  function handleEmailUpdate(e) {
    setEmail(e);
  }

  function handleTabClick(option) {
    setProfileView(option);
  };


  function handleUserProfileUpdate(e) {
    setUserProfile(e);
  }

  async function getEmailFromLocalStorage() {
    let localStorageValue = localStorage.getItem("userKey");
    let return_value = 'Please login';
    if (localStorageValue === null || localStorageValue === undefined || localStorageValue === "Please login") {
      return_value = "Please login";
      return return_value;
    } else {

      console.log('In getEmailFromLocalStorage. LocalStorageValue is not null');


      localStorageValue = await decryptValue(localStorageValue);
      console.log('Decrypted response plain: ' + localStorageValue);

      console.log('Decrypted response json stringified: ' + JSON.stringify(localStorageValue));

      const user_json = JSON.parse(localStorageValue);

      console.log('Decrypted response: ' + localStorageValue);
      console.log('Decrypted response object: ' + user_json); 
      console.log('Decrypted response email var: ' + user_json.email); 


      return_value = user_json.email;

    }
    return return_value;
  }

  async function getUserFromLocalStorage() {
    let localStorageValue = localStorage.getItem("userKey");
    let return_value = 'Please login';
    // console.log("Local storage value: ", localStorageValue);
    if (localStorageValue === null || localStorageValue === undefined || localStorageValue === "Please login" || localStorageValue === "Error :(" || localStorageValue === "Error decrypting value") {
      return_value = "Please login";
      return return_value;
    } else {

      localStorageValue = await decryptValue(localStorageValue);

      // console.log("this is the problem child: " + localStorageValue);
      const user_json = JSON.parse(localStorageValue);

      // console.log('Decrypted response: ' + localStorageValue);
      // console.log('Decrypted response object: ' + user_json); 
      // console.log('Decrypted response email var: ' + user_json.email); 

      return_value = user_json;

    }
    return return_value;
  }


  function removeUserFromLocalStorage() {
    const user = "Please login";
    handleEmailUpdate(user);
    localStorage.setItem("userKey", user);
  }

  const handleClick = (option) => {
    setProfileView(option);
    console.log("Clicked top nav. Now in: " + profileView);
    // Optionally, add logic to handle actions based on selectedOption here
  };

  if (userProfile === undefined || userProfile === null || userProfile.uid === null) {
    return  (
      <div className="profile">
        <div>
          <GetGoogleLogin
            className="googleLogin"
            email={email}
            onEmailChange={handleEmailUpdate}
            handleUserProfileUpdate={handleUserProfileUpdate}
          />
        </div>
      </div>
    )

  }

  return (
    <div className="profile">
      <div>
        {email === "Please login" || userProfile === undefined  || email === "Loading..." || userProfile == "Please login" ? (
          <GetGoogleLogin
            className="googleLogin"
            email={email}
            onEmailChange={handleEmailUpdate}
            handleUserProfileUpdate={handleUserProfileUpdate}
          />
        ) : (
          <div>
            <nav className="profile--tabs">
            <div>
            {/* <Tabs defaultActiveKey="Settings" centered activeKey={profileView} onChange={handleTabClick}>
              
            <TabPane tab="Settings" key="Settings">
                <div>
                  <ProfileSettings userProfile={userProfile} removeUser={removeUserFromLocalStorage}/>
                </div>
              </TabPane>
              <TabPane tab="My Shorts" key="My Shorts">
                <div>
                  <MyShorts userProfile={userProfile}/>
                </div>
              </TabPane>
              <TabPane tab="Upload" key="Upload">
                <Upload id={email} userProfile={userProfile} />
              </TabPane>
            </Tabs> */}
                <Tabs defaultActiveKey="Settings" centered activeKey={profileView} onChange={handleTabClick} items={items} />

            </div>
            </nav>
          </div>
        
        )}
      </div>
    </div>
  );
}
